/* eslint-disable max-len */
import React, {FC, useCallback, useState, MouseEvent} from 'react';
import Helmet from 'react-helmet';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {asUSD} from '../utils/formatters';
import {TIERS, LINE_ITEMS} from '../constants/plans';
import Layout from '../layouts/Primary';
import description1 from '../assets/images/description-1.png';
import description2 from '../assets/images/description-2.png';
import description3 from '../assets/images/description-3.png';
import featuresPerfAudit from '../assets/images/features-performance-audit.jpg';
import featuresBestPractices from '../assets/images/features-best-practices-suggestions.jpg';
import featuresAcc from '../assets/images/features-accessibility-audit.jpg';
import featuresSeo from '../assets/images/features-seo-audit.jpg';
import integrations from '../assets/images/integrations-with-us.png';
import lighthouse from '../assets/images/beam-fire-image-header.jpg';

type LineItemProps = {
	id: string;
	name: string;
	title: string;
}

const LineItem: FC<LineItemProps> = ({id, name, title}) =>
	<OverlayTrigger
		placement="top-start"
		overlay={<Tooltip id={id}>{title}</Tooltip>}>
		<div className="media-body">{name}</div>
	</OverlayTrigger>;

const getBaseItems = (items: string[]) =>
	items
		.map((name, i) =>
			<li key={i}
				className="media">
				<i className={'fas fa-check'} />
				<div className="media-body">{name}</div>
			</li>
		);

const getLineItems = (tier: number) =>
	LINE_ITEMS
		.map(({name, title, minTier}, i) =>
			<li key={i}
				className="media">
				<i className={`fas fa-${minTier <= tier ? 'check' : 'times'}`} />
				<LineItem
					id={`${name.replace(' ', '').toLocaleLowerCase()}-${minTier}`}
					name={name}
					title={title}
				/>
			</li>
		);

const Index: FC = () => {
	const [currentTab, setCurrentTab] = useState('tab-1');
	const onTabClick = useCallback((event: MouseEvent<HTMLAnchorElement>) => {
		event.preventDefault();
		const target = event.target as HTMLElement;
		if (target.tagName === 'A') {
			const nextTab = target.id.replace('nav-', '');
			setCurrentTab(nextTab);
		}
	}, [currentTab]);
	return <Layout>
		<Helmet
			title="BeamFire Audit and Continuous Integration"
		/>
		<header id="header"
			className="header">
			<div className="header-content">
				<div className="container">
					<div className="row">
						<div className="col-lg-6 col-xl-5">
							<div className="text-container">
								<h1>BeamFire Cloud</h1>
								<p className="p-large">Use BeamFire Continuous Integration to automate your website auditing and gain valuable insights into Search Engine Optimizations, Security, Accessibility, Progressive Web Apps, and Speed Tooling at scale.</p>
								<a className="btn-solid-lg page-scroll"
									href="/sign-up/">SIGN UP</a>
							</div>
						</div>
						<div className="col-lg-6 col-xl-7">
							<div className="image-container">
								<div className="img-wrapper">
									<img title="Providing the light in the dark"
										alt="lighthouse at night lighting up the ocean"
										className="img-fluid"
										src={lighthouse}
										style={{maxWidth: '90%'}} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
		<svg className="header-svg"
			data-name="Layer 1"
			xmlns="http://www.w3.org/2000/svg"
			preserveAspectRatio="none"
			viewBox="0 1 1920 310">
			<path className="cls-1"
				d="M0,283.054c22.75,12.98,53.1,15.2,70.635,14.808,92.115-2.077,238.3-79.9,354.895-79.938,59.97-.019,106.17,18.059,141.58,34,47.778,21.511,47.778,21.511,90,38.938,28.418,11.731,85.344,26.169,152.992,17.971,68.127-8.255,115.933-34.963,166.492-67.393,37.467-24.032,148.6-112.008,171.753-127.963,27.951-19.26,87.771-81.155,180.71-89.341,72.016-6.343,105.479,12.388,157.434,35.467,69.73,30.976,168.93,92.28,256.514,89.405,100.992-3.315,140.276-41.7,177-64.9V0.24H0V283.054Z" />
			<title>BeamFire Continuous Audit Integration</title>
		</svg>
		<div className="cards-1">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="above-heading">Speed + Accessibility + Security</div>
						<h2 className="h2-heading">Website Audit Automation At Scale</h2>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-12">
						<div className="card">
							<div className="card-image">
								<img className="img-fluid"
									src={description1}
									alt="engineering team" />
							</div>
							<div className="card-body">
								<h4 className="card-title">Engineering</h4>
								<p>Provide engineering teams cutting-edge insight into new features and clearly see security and performance impact for each deployment, while also reducing risk and keeping up with development</p>
							</div>
						</div>
						<div className="card">
							<div className="card-image">
								<img className="img-fluid"
									src={description2}
									alt="brand and product team" />
							</div>
							<div className="card-body">
								<h4 className="card-title">Brand</h4>
								<p>Easily enhance brand quality and loyalty by ensuring security and accessibility as part of Continuous Integration (CI), improving customer engagement and retention</p>
							</div>
						</div>
						<div className="card">
							<div className="card-image">
								<img className="img-fluid"
									src={description3}
									alt="metrics and Dashboard" />
							</div>
							<div className="card-body">
								<h4 className="card-title">Metrics and Dashboard</h4>
								<p>BeamFire collects historical data for reports, progress, and regression testing </p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="features"
			className="tabs">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="above-heading">FEATURES</div>
						<h2 className="h2-heading">Website Audit Automation</h2>
						<p className="p-heading">Take your website to the next level and automatize your auditing tasks to save time for product development. BeamFire can provide results on a per-build basis, providing immediate and transparent insight into new features, shifting left earlier in the software development lifecycle (SDLC). Spend more time on development and avoid wasting cycles on SEO, security, and accessibility regression testing.</p>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-12">
						<ul className="nav nav-tabs"
							id="argoTabs"
							role="tablist">
							<li className="nav-item">
								<a className={`nav-link${currentTab === 'tab-1' ? ' active' : ''}`}
									id="nav-tab-1"
									onClick={onTabClick}
									data-toggle="tab"
									href="#tab-1"
									role="tab"
									aria-controls="tab-1"
									aria-selected="true">
									<i className="fas fa-tachometer-alt" />Security</a>
							</li>
							<li className="nav-item">
								<a className={`nav-link${currentTab === 'tab-2' ? ' active' : ''}`}
									id="nav-tab-2"
									onClick={onTabClick}
									data-toggle="tab"
									href="#tab-2"
									role="tab"
									aria-controls="tab-2"
									aria-selected="false">
									<i className="fas fa-lock" />Performance</a>
							</li>
							<li className="nav-item">
								<a className={`nav-link${currentTab === 'tab-3' ? ' active' : ''}`}
									id="nav-tab-3"
									onClick={onTabClick}
									data-toggle="tab"
									href="#tab-3"
									role="tab"
									aria-controls="tab-3"
									aria-selected="false">
									<i className="fas fa-users" />Accessibility </a>
							</li>
							<li className="nav-item">
								<a className={`nav-link${currentTab === 'tab-4' ? ' active' : ''}`}
									id="nav-tab-4"
									onClick={onTabClick}
									data-toggle="tab"
									href="#tab-4"
									role="tab"
									aria-controls="tab-4"
									aria-selected="false">
									<i className="fas fa-chart-bar" />SEO </a>
							</li>
						</ul>
						<div className="tab-content"
							id="argoTabsContent">
							<div className={`tab-pane fade${currentTab === 'tab-1' ? ' active show' : ''}`}
								id="tab-1"
								role="tabpanel"
								aria-labelledby="tab-1">
								<div className="row">
									<div className="col-lg-6">
										<div className="image-container">
											<img title="Pencil and Paper"
												alt="Pencil and Paper with engineer drafts"
												className="img-fluid"
												src={featuresBestPractices} />
										</div>
									</div>
									<div className="col-lg-6">
										<div className="text-container">
											<h3>Security and Developer Tools</h3>
											<p>Ensure secure common development and website Best Practices are included with each build and using real time security monitoring. Learn about insecure practices to avoid, invalid HTML, or deprecated CSS or JavaScript APIs in use. </p>
											<ul className="list-unstyled li-space-lg">
												<li className="media">
													<i className="fas fa-square" />
													<div className="media-body">Vulnerability Scan</div>
												</li>
												<li className="media">
													<i className="fas fa-square" />
													<div className="media-body">Dynamic Application Security Testing (DAST)</div>
												</li>
												<li className="media">
													<i className="fas fa-square" />
													<div className="media-body">Automated Vulnerability Confirmation</div>
												</li>
												<li className="media">
													<i className="fas fa-square" />
													<div className="media-body">Security Audit Checklist</div>
												</li>
												<li className="media">
													<i className="fas fa-square" />
													<div className="media-body">Best Practices Checklist</div>
												</li>
												<li className="media">
													<i className="fas fa-square" />
													<div className="media-body">Suggestions</div>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
							<div className={`tab-pane fade${currentTab === 'tab-2' ? ' active show' : ''}`}
								id="tab-2"
								role="tabpanel"
								aria-labelledby="tab-2">
								<div className="row">
									<div className="col-lg-6">
										<div className="image-container">
											<img title="car tachometer"
												alt="car tachometer orange and black"
												className="img-fluid"
												src={featuresPerfAudit} />
										</div>
									</div>
									<div className=" col-lg-6">
										<div className="text-container">
											<h3>Performance Tools</h3>
											<p>Gain key metrics to improve and learn about opportunities to help your page load faster. See valuable diagnostics about your apps performance. </p>
											<ul className="list-unstyled li-space-lg">
												<li className="media">
													<i className="fas fa-square" />
													<div className="media-body">Audit Checklist</div>
												</li>
												<li className="media">
													<i className="fas fa-square" />
													<div className="media-body">Improvement Checklist </div>
												</li>
												<li className="media">
													<i className="fas fa-square" />
													<div className="media-body">Suggestions</div>
												</li>
												<li className="media">
													<i className="fas fa-square" />
													<div className="media-body">Mobile Friendliness</div>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
							<div className={`tab-pane fade${currentTab === 'tab-3' ? ' active show' : ''}`}
								id="tab-3"
								role="tabpanel"
								aria-labelledby="tab-3">
								<div className="row">
									<div className="col-lg-6">
										<div className="image-container">
											<img title="Everyone is Welcome"
												alt="Wall with Everyone is Welcome painted on it"
												className="img-fluid"
												src={featuresAcc} />
										</div>
									</div>
									<div className="col-lg-6">
										<div className="text-container">
											<h3>Accessibility Tools</h3>
											<p>While accessibility is best done with a manual process, we can automatically detect common accessibility issues and provide feedback for you. We check for contrast, navigation, and text on your site to streamline basic audit failures.</p>
											<ul className="list-unstyled li-space-lg">
												<li className="media">
													<i className="fas fa-square" />
													<div className="media-body">Audit Checklist</div>
												</li>
												<li className="media">
													<i className="fas fa-square" />
													<div className="media-body">Manual Checklist</div>
												</li>
												<li className="media">
													<i className="fas fa-square" />
													<div className="media-body">App bundle optimizations</div>
												</li>
												<li className="media">
													<i className="fas fa-square" />
													<div className="media-body">Suggestions and dead code discovery</div>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
							<div className={`tab-pane fade${currentTab === 'tab-4' ? ' active show' : ''}`}
								id="tab-4"
								role="tabpanel"
								aria-labelledby="tab-4">
								<div className="row">
									<div className="col-lg-6">
										<div className="image-container">
											<img title="SEO - search engine optimization"
												alt="the letters S, E, and O"
												className="img-fluid"
												src={featuresSeo} />
										</div>
									</div>
									<div className="col-lg-6">
										<div className="text-container">
											<h3>Search Engine Optimization Tools</h3>
											<p>Our tools check that your page is optimized for search engine results ranking. Alongside basic speed and accessibility improvements, this category helps you clean up your website and add in any missing metatag or HTML information.</p>
											<ul className="list-unstyled li-space-lg">
												<li className="media">
													<i className="fas fa-square" />
													<div className="media-body">Audit Checklist</div>
												</li>
												<li className="media">
													<i className="fas fa-square" />
													<div className="media-body">Manual Checklist</div>
												</li>
												<li className="media">
													<i className="fas fa-square" />
													<div className="media-body">Missing Tag Checks</div>
												</li>
												<li className="media">
													<i className="fas fa-square" />
													<div className="media-body">Status Code Checks</div>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="details"
			className="basic-1">
			<div className="container">
				<div className="row">
					<div className="col-lg-6">
						<div className="text-container">
							<h2>Now Is The Time To Upgrade Your Auditing Solution</h2>
							<p>Changes to your site performance, security, seo, and accessibility will impact your business. With the help of BeamFire&apos;s automated continuous integration, you can maintain and deploy secure efficient websites. Keep your customers happy and loyal. </p>
							<ul className="list-unstyled li-space-lg">
								<li className="media">
									<i className="fas fa-square" />
									<div className="media-body">Add to existing Continuous Integration (CI) process</div>
								</li>
								<li className="media">
									<i className="fas fa-square" />
									<div className="media-body">Integration Friendly - GitHub, GitLab, Slack, Teams, CircleCI, TravisCI ... and more!</div>
								</li>
								<li className="media">
									<i className="fas fa-square" />
									<div className="media-body">Easy to use</div>
								</li>
								<li className="media">
									<i className="fas fa-square" />
									<div className="media-body">Actionable insights per page</div>
								</li>
								<li className="media">
									<i className="fas fa-square" />
									<div className="media-body">Reasonable pricing</div>
								</li>
							</ul>
							<a className="btn-solid-reg page-scroll"
								href="/sign-up/">SIGN UP</a>
						</div>
					</div>
					<div className="col-lg-6">
						<div className="image-container">
							<img title="Integrations Currently Supported"
								alt="Integration platforms including GitHub, GitLab, Circle CI, Travis CI, GitHub Actions, Jenkins, Slack and Bitbucket"
								className="img-fluid"
								src={integrations} />
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="pricing"
			className="cards-2">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="above-heading">SIMPLIFIED PRICING</div>
						<h2 className="h2-heading">Pricing Options</h2>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-12">
						<div className="card">
							<div className="card-body">
								<div className="card-title">{TIERS.t1.title}</div>
								<div className="price">
									<span className="currency">*</span>
									<span className="value">Free</span>
								</div>
								<div className="frequency">10 day trial (*{asUSD(TIERS.t1.monthly)}/mo post-trial)</div>
								<div className="frequency">(or {asUSD(TIERS.t1.annual)} annually - <strong>{TIERS.t1.annualDesc}</strong>)</div>
								<div className="divider" />
								<ul className="list-unstyled li-space-lg">
									{getBaseItems(TIERS.t1.baseItems)}
									{getLineItems(1)}
								</ul>
								<div className="button-wrapper">
									<a
										className="btn-solid-reg page-scroll"
										href={`/sign-up/?${TIERS.t1.id}`}>
										SIGN UP
									</a>
								</div>
							</div>
						</div>
						<div className="card">
							<div className="card-body">
								<div className="card-title">{TIERS.t2.title}</div>
								<div className="price">
									<span className="currency">$</span>
									<span className="value">{TIERS.t2.monthly}</span>
								</div>
								<div className="frequency">monthly</div>
								<div className="frequency">(or {asUSD(TIERS.t2.annual)} annually - <strong>{TIERS.t2.annualDesc}</strong>)</div>
								<div className="divider" />
								<ul className="list-unstyled li-space-lg">
									{getBaseItems(TIERS.t2.baseItems)}
									{getLineItems(2)}
								</ul>
								<div className="button-wrapper">
									<a
										className="btn-solid-reg page-scroll"
										href={`/sign-up/?${TIERS.t2.id}`}>
										SIGN UP
									</a>
								</div>
							</div>
						</div>
						<div className="card">
							<div className="card-body">
								<div className="card-title">{TIERS.t3.title}</div>
								<div className="price">
									<span className="currency">$</span>
									<span className="value">{TIERS.t3.monthly}</span>
								</div>
								<div className="frequency">monthly</div>
								<div className="frequency">(or {asUSD(TIERS.t3.annual)} annually - <strong>{TIERS.t3.annualDesc}</strong>)</div>
								<div className="divider" />
								<ul className="list-unstyled li-space-lg">
									{getBaseItems(TIERS.t3.baseItems)}
									{getLineItems(3)}
								</ul>
								<div className="button-wrapper">
									<a
										className="btn-solid-reg page-scroll"
										href={`/sign-up/?${TIERS.t3.id}`}>
										SIGN UP
									</a>
								</div>
							</div>
						</div>
						<p>Need more domains? <a href="mailto:contact@beamfire.io">Contact Us</a> for more options and enterprise solutions/pricing. </p>
						<p className="p-small">*While tests are unlimited for all tiers, there is a maximum concurrency of 1 test <strong>per domain</strong> to prevent <a
							rel="noreferrer"
							href="https://en.wikipedia.org/wiki/Thundering_herd_problem"
							target="_blank">thundering herds</a>. </p>
					</div>
				</div>
			</div>
		</div>
	</Layout>;
};

export default Index;
