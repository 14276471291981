import React, {FC, Fragment, ReactNode} from 'react';
import {GlobalStyle} from '../styles/GlobalStyle';
import Header from '../components/Header';
import Footer from '../components/Footer';

type Props = {
	children: ReactNode;
}

const Main: FC<Props> = ({children}) =>
	<Fragment>
		<GlobalStyle />
		<Header />
		<main>{children}</main>
		<Footer />
	</Fragment>;

export default Main;
