import React, {FC} from 'react';
import styled from 'styled-components';
import logo from '../assets/images/logo.png';
import {getBrowserElementTop} from '../utils/browser';
import {UserWidget} from '../porter-package';

const StyledUserWidget = styled(UserWidget)`
	& {
		text-transform: uppercase;
	}
`;

const adjustMenu = () => {
	const navBar = document.querySelector('.navbar');
	if (!navBar) {
		return;
	}

	if (getBrowserElementTop(navBar) > 60) {
		navBar.classList.add('top-nav-collapse');
	} else {
		navBar.classList.remove('top-nav-collapse');
	}
};

(() => {
	if (typeof window !== 'undefined') {
		window.addEventListener('scroll', adjustMenu);
		window.addEventListener('load', adjustMenu);
	}
})();

const Header: FC = () =>
	<nav className="navbar navbar-expand-lg navbar-dark navbar-custom fixed-top">
		<div className="container">

			<a className="navbar-brand logo-image"
				href="/">
				<img src={logo}
					alt="BeamFire logo" />
			</a>

			<button className="navbar-toggler"
				type="button"
				data-toggle="collapse"
				data-target="#navbarsExampleDefault"
				aria-controls="navbarsExampleDefault"
				aria-expanded="false"
				aria-label="Toggle navigation">
				<span className="navbar-toggler-awesome fas fa-bars" />
				<span className="navbar-toggler-awesome fas fa-times" />
			</button>

			<div className="collapse navbar-collapse"
				id="navbarsExampleDefault">
				<ul className="navbar-nav ml-auto">
					<li className="nav-item">
						<a className="nav-link page-scroll"
							href="/#header">HOME <span className="sr-only">(current)</span></a>
					</li>
					<li className="nav-item">
						<a className="nav-link page-scroll"
							href="/#features">FEATURES</a>
					</li>
					<li className="nav-item">
						<a className="nav-link page-scroll"
							href="/#details">DETAILS</a>
					</li>
					<li className="nav-item">
						<a className="nav-link page-scroll"
							href="/#pricing">PRICING</a>
					</li>
				</ul>
				<span className="nav-item">
					<StyledUserWidget />
				</span>
			</div>
		</div>
	</nav>;

export default Header;
