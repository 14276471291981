
export const getBrowserElementTop = (element: Element, offset = 0): number => {
	const bodyRect = document.body.getBoundingClientRect();
	if (!element) {
		return offset;
	}

	const elemRect = element.getBoundingClientRect();
	return elemRect.top - bodyRect.top + offset;
};

export const getPathname = (): string =>
	typeof location !== 'undefined' ?
		location.pathname || '' :
		'';

export const getPath = (url: string): string => url ? new URL(url).pathname : '';
