type TierList = {
	[key: string]: PlanPackage
}

export const TIERS: TierList = {
	t1: {
		id: 't=1',
		tier: 1,
		monthly: 9.99,
		annual: 9.99 * 11,
		annualDesc: '1 month free!',
		title: 'BASIC',
		baseItems: [

			// '1 User',
			'Single Domain'
		],
		planIds: {
			monthly: 'P-2XG21875BJ1094306MEHC63Q',
			annual: 'P-83M26950AB3235029MEHDDDY'
		}
	},
	t2: {
		id: 't=2',
		tier: 2,
		monthly: 99.99,
		annual: 99.99 * 11,
		annualDesc: 'Save 8%!',
		title: 'PRO',
		baseItems: [

			// '5 Users',
			'5 Domains'
		],
		planIds: {
			monthly: 'P-1RG467946H3648711MEHDFXY',
			annual: 'P-86793237L1181471VMEHDGEQ'
		}
	},
	t3: {
		id: 't=3',
		tier: 3,
		monthly: 399.99,
		annual: 399.99 * 10,
		annualDesc: 'Save 15%',
		title: 'COMPLETE',
		baseItems: [

			// '10 Users',
			'10 Domains'
		],
		planIds: {
			monthly: 'P-6FX70468C08606030MEHDGPY',
			annual: 'P-0M808166H6692104MMEHDGZA'
		}
	}
};

export const LINE_ITEMS = [
	{
		name: 'Configurable Crawler',
		title: 'Customize the site paths our crawler takes',
		minTier: 1
	},
	{
		name: 'Unlimited Integration Tests*',
		title: 'Run as many integration tests from your CI as you want',
		minTier: 1
	},
	{
		name: 'Site Audit: Accessibility',
		title: 'Receive full audit results on accessibility',
		minTier: 1
	},
	{
		name: 'Site Audit: SEO',
		title: 'Receive full audit results on SEO',
		minTier: 1
	},
	{
		name: 'Site Audit: Performance',
		title: 'Receive full audit results on Page Performance',
		minTier: 1
	},
	{
		name: 'Automatic Daily Site Audit Checks',
		title: 'Run site audit checks every day',
		minTier: 2
	},
	{
		name: 'Historical Data',
		title: 'Retain previous day reports and CI tests',
		minTier: 2
	},
	{
		name: 'Security Audit: Vulnerability Scan',
		title: 'Receive full audit results on live security vulnerabilities',
		minTier: 3
	},
	{
		name: 'Security Threat Analysis',
		title: 'API to detect and analyze potential threats',
		minTier: 3
	},
	{
		name: 'Automated Vulnerability Confirmation',
		title: 'Our system will automatically determine soundness of discovered vulnerabilities',
		minTier: 3
	},
	{
		name: 'Automatic Daily Security Audit Checks',
		title: 'Run a security audit check every day for new security issues',
		minTier: 3
	},
	{
		name: 'Expert Audit Help',
		title: 'Contact our team of experts to discuss detailed solutions for fixes',
		minTier: 3
	}
];
